<style lang="scss">section {
  grid-area: title;
  align-self: end;
  justify-self: end;
  text-align: right;
}
section :global(h1) {
  color: white;
  font-family: "Monoton";
  font-size: 3rem;
  font-weight: 100;
  line-height: 1em;
  text-shadow: 0 0 7px #fff;
  text-transform: uppercase;
}
@media screen and (max-width: 1150px) {
  section :global(h1) {
    font-size: 2rem;
  }
}
section :global(h2) {
  font-size: 0.8rem;
}</style>

<section>
  <slot />
</section>
