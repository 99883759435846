<h1>Terms and conditions</h1>
<p>Facts &amp; Disclaimer
We are not an investment fund, let alone an hedge fund in the classical sense. We are a cooperative of investors. Each investor is a member of the cooperative and buys a one time membership fee of 30 euro. This membership represents the one vote each member has in the yearly member meeting of the cooperative, no matter how many shares are bought. One member - One vote.</p>
<p>A member is obliged to buy at least one share. This first share is called the obligatory share. Prize of one share is 30 euro, so the minimum total investment is 60 euros. Each member can buy as many voluntary shares as she or he wants immediately or later.</p>
<p>One time per month, at the beginning of each month your money is invested in a new Serie (always or just when you put money in?). The value of each serie is different and depends on the Net Asset Value on the moment the money is sent to our Broker in New York, Interactive Brokers. From that moment on it starts on a daily base to gain or to lose. Gain or loss depends on two major indicators 1. the value fluctuations of the stocks and 2. the value fluctuations in the euro/dollar conversion rates. BUT note: Nothing is guaranteed: past performance is not indicative of future results!</p>
<p>The investment returns depend on what the best investors in Wall Street make. That&#39;s the promise of the software algorithm. That we cannot know or predict (nor promise, legally).</p>


<style>
* {
  color: black;
}</style>


<script context="module">
export const META = {};
</script>