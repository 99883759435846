<script>
  import Home from '/routes/Home.svelte'
  import NotFound from '/routes/NotFound.svelte'
  import AdminError from '/routes/admin/Error.svelte'
  import { MembersCreate, MembersEdit, MembersList } from '/routes/admin/members/index.js'
  import { AdminsCreate, AdminsList } from '/routes/admin/admins/index.js'
  import { Shares } from '/routes/admin/shares/index.js'
  import { Burger, Nav } from '/sections/admin/index.js'
  import { RHC } from '/lib/index.js'
  import { onMount } from 'svelte'
  import Notifications from 'svelte-notifications'
  import { Router, Route } from 'yrv'
  import Modal from 'svelte-simple-modal';

  let metamask = false
  let network = null
  let account = null
  let isAdmin = false
  let coop = RHC.new()

  $: {
    if (!metamask || network !== '42') {
      isAdmin = false
    } else {
      coop.isAdmin(account).then(_isAdmin => {
        isAdmin = _isAdmin
      })
    }
  }

  onMount(async () => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        account = (await window.ethereum.enable())[0]
        network = window.ethereum.networkVersion
        metamask = true

        window.ethereum.on('accountsChanged', accounts => {
          account = accounts[0]
        })

        window.ethereum.on('networkChanged', chain => {
          network = chain
        })
      }
    } catch (e) {
      console.log(e.message)
    }
  })
</script>

<style lang="scss" global>/*---------------------------------------------------------------------------*
 * SF Mono
 *---------------------------------------------------------------------------*/
/** Bold */
@font-face {
  font-family: "New York";
  font-weight: 700;
  src: url("fonts/NewYorkExtraLarge-Heavy.otf");
}
@font-face {
  font-family: "SF Mono";
  font-weight: 700;
  src: url("https://sf.abarba.me/SFMono-Bold.otf");
}
/** Bold Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 700;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-BoldItalic.otf");
}
/** Heavy */
@font-face {
  font-family: "SF Mono";
  font-weight: 800;
  src: url("https://sf.abarba.me/SFMono-Heavy.otf");
}
/** Heavy Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 800;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-HeavyItalic.otf");
}
/** Light */
@font-face {
  font-family: "SF Mono";
  font-weight: 200;
  src: url("https://sf.abarba.me/SFMono-Light.otf");
}
/** Light Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 200;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-LightItalic.otf");
}
/** Medium */
@font-face {
  font-family: "SF Mono";
  font-weight: 500;
  src: url("https://sf.abarba.me/SFMono-Medium.otf");
}
/** Medium Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 500;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-MediumItalic.otf");
}
/** Regular */
@font-face {
  font-family: "SF Mono";
  font-weight: 400;
  src: url("https://sf.abarba.me/SFMono-Regular.otf");
}
/** Regular Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 400;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-RegularItalic.otf");
}
/** Semibold */
@font-face {
  font-family: "SF Mono";
  font-weight: 600;
  src: url("https://sf.abarba.me/SFMono-Semibold.otf");
}
/** Semibold Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 600;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-SemiboldItalic.otf");
}
/***** reset *****/
:global(html) {
  background-color: #e94057;
  background-image: linear-gradient(-35deg, #8a2387 0%, #e94057 50%, #f27121 100%);
  box-sizing: border-box;
  font-size: 15px;
}
@media screen and (max-width: 980px) {
  :global(html) {
    font-size: 13px;
  }
}

:global(*),
:global(*:before),
:global(*:after) {
  box-sizing: inherit;
}

:global(body),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(p),
:global(ol),
:global(ul),
:global(a) {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  padding: 0;
  font-family: "SF Mono", monospace;
  font-weight: 100;
  line-height: 1.3em;
  word-wrap: break-word;
}

:global(ol),
:global(ul) {
  list-style: none;
}

:global(img) {
  max-width: 100%;
  height: auto;
}

:global(input),
:global(button),
:global(select),
:global(textarea) {
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

:global(label) {
  display: block;
  font-size: 0.9rem;
}

:global(input) {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 4px;
  font-weight: 100;
  color: #fff;
  padding: 1.1em 0.5em;
}

:global(input:focus) {
  outline-width: none;
  outline: none;
}

:global(a:hover) {
  color: white;
}

:global(table.list) {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

:global(table.list) :global(th) {
  color: #fff;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 1rem;
}

:global(table.list) :global(tr:nth-child(odd)) {
  background-color: rgba(255, 255, 255, 0.05);
}

:global(table.list) :global(td) {
  padding: 1rem;
}

:global(table.list) :global(td:last-of-type) {
  display: flex;
  justify-content: flex-end;
}

:global(.flex) {
  display: flex;
}

:global(.flex.row) {
  flex-direction: row;
}

:global(.flex.column) {
  flex-direction: column;
}

:global(.flex.centered) {
  align-items: center;
  justify-content: center;
}

:global(.flex.justify-center) {
  justify-content: center;
}

:global(.info) {
  font-size: 0.8rem;
  color: #ffaaaa;
  min-height: 1.3em;
  line-height: 1.3em;
  white-space: pre-wrap;
}

:global(.small) {
  font-size: 0.9rem;
}

:global(.x-small) {
  font-size: 0.8rem;
}

:global(.strong) {
  color: white;
  font-weight: 600;
}

:global(.info) > :global(.strong) {
  color: #ffaaaa;
}

:global(.space-bottom) {
  margin-bottom: 1rem;
}

:global(.space-right) {
  margin-right: 1rem;
}

:global(.space-left) {
  margin-left: 1rem;
}

:global(.space-top) {
  margin-top: 1rem;
}

:global(.form) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: scroll;
}

:global(.form) :global(.entry) {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

:global(.form) :global(.entry) :global(label) {
  font-size: 0.8rem;
  text-transform: uppercase;
}

:global(.scroll) {
  overflow: scroll;
}

:global(::-moz-placeholder) {
  color: #fff;
  opacity: 0.2;
}

:global(:-ms-input-placeholder) {
  color: #fff;
  opacity: 0.2;
}

:global(::placeholder) {
  color: #fff;
  opacity: 0.2;
}

:global(.notification) {
  border-radius: 2px;
  font-size: 0.8rem;
  opacity: 1;
  color: yellow;
}

:global(.notification-button) {
  border-radius: 0;
}</style>

<Notifications>
  <Modal>
  <Router let:router>
    <Route exact component={Home} />
    {#if isAdmin}
      <Route exact path="/admin" redirect="/admin/members" />
      <Route exact path="/admin/members">
        <Nav />
        <Burger />
        <MembersList />
      </Route>
      <Route exact path="/admin/members/create">
        <Nav />
        <Burger />
        <MembersCreate />
      </Route>
      <Route exact path="/admin/members/edit/:address" let:router>
        <Nav />
        <Burger />
        <MembersEdit {router} />
      </Route>
      <Route exact path="/admin/shares">
        <Nav />
        <Burger />
        <Shares />
      </Route>
      <Route exact path="/admin/admins">
        <Nav />
        <Burger />
        <AdminsList account={account}/>
      </Route>
      <Route exact path="/admin/admins/create">
        <Nav />
        <Burger />
        <AdminsCreate />
      </Route>
    {:else}
      <Route exact path="/admin">
        <AdminError {metamask} {network} {isAdmin} />
      </Route>
    {/if}
    <Route fallback component={NotFound} />
  </Router>
  </Modal>
</Notifications>
