<script>
  import { Action, Data, Nav, Title } from '/sections/home/index.js'
</script>

<style type="text/scss">div.wrapper {
  min-height: 100vh;
  padding: 1rem;
}
div.wrapper main {
  display: grid;
  grid-template-rows: calc(50% - 0.5rem) calc(50% - 0.5rem);
  grid-template-columns: 45% 45%;
  grid-template-areas: "title nav" "data action";
  grid-column-gap: 10%;
  grid-row-gap: 1rem;
  min-height: calc(100vh - 2rem);
}
@media screen and (max-width: 980px) {
  div.wrapper main {
    align-content: start;
    justify-content: center;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas: "nav" "title" "action" "data";
    grid-column-gap: 10%;
    grid-row-gap: 1rem;
  }
}</style>

<div class="wrapper">
  <main>
    <Title />
    <Data />
    <Nav />
    <Action />
  </main>
</div>
