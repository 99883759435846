<script>
  import Animate from '/components/Animate.svelte'
  import { screen } from '/stores/screen.js'
</script>

<style type="text/scss">section.title {
  grid-area: title;
  align-self: end;
  justify-self: end;
}
section.title h1 {
  color: white;
  font-family: "Monoton";
  font-size: 3rem;
  font-weight: 100;
  line-height: 1em;
  text-shadow: 0 0 7px #fff;
  text-transform: uppercase;
}
@media screen and (max-width: 1150px) {
  section.title h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 980px) {
  section.title {
    align-self: center;
    justify-self: center;
  }
}</style>

<section class="title">
  {#if $screen == 'dashboard'}
    <Animate>
      <h1>Your portfolio</h1>
    </Animate>
  {:else}
    <Animate>
      <h1>Stage Robin Hood Coop</h1>
    </Animate>
  {/if}
</section>
