<style lang="scss">section {
  grid-area: nav;
  align-self: start;
  justify-self: end;
  text-align: right;
}</style>

<section>
  <slot />
</section>
