<style type="text/scss">section {
  min-height: 100vh;
  width: 100vw;
}
section h1 {
  color: white;
  font-family: "Monoton";
  font-size: 3rem;
  font-weight: 100;
  line-height: 1em;
  text-shadow: 0 0 7px #fff;
  text-transform: uppercase;
}
@media screen and (max-width: 1150px) {
  section h1 {
    font-size: 2rem;
  }
}
section h2 {
  font-size: 1rem;
}</style>

<section class="flex column centered">
  <h1>404</h1>
  <h2>not found</h2>
</section>
